import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import FormInput from '../../../Form/Input';
import StringHelper from '../../../../helpers/string-helper';
import ToasterHelper from '../../../../helpers/toaster-helper';
import { loadProperty } from '../../../../store/property/thunks';

const Coupon = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const travel = useSelector(state => state.travel);
  const reservation = useSelector(state => state.reservation);
  const { 
    couponCode: appliedCouponCode, 
    percentageDiscount, 
    totalAdults, 
    totalChildren, 
    children ,
    askSuiteId
  } = reservation;
  const { arrivalDate, departureDate } = travel;
  const { reservation: { couponCode } } = props;
  const [currentCoupon, setCurrentCoupon] = useState(couponCode);

  const hasAValidCoupon =
    StringHelper.isNotEmpty(appliedCouponCode) &&
    StringHelper.isNotEmpty(percentageDiscount) &&
    percentageDiscount > 0;

  const handleCouponSubmit = () => {
    if (
      StringHelper.isNotEmpty(currentCoupon) &&
      currentCoupon !== couponCode
    ) {
      dispatch(
        loadProperty(
          arrivalDate,
          departureDate,
          currentCoupon,
          null,
          totalAdults,
          totalChildren,
          children,
          null,
          null,
          null,
          askSuiteId,
          history,
          true
        )
      );
    } else if (currentCoupon !== couponCode) {
      ToasterHelper.error(t('errors.emptyCoupon'));
    } else {
      ToasterHelper.error(t('errors.otherCoupon'));
    }
  };

  const handleCouponRemoval = () => {
    setCurrentCoupon('');
    dispatch(
      loadProperty(
        arrivalDate,
        departureDate,
        null,
        null,
        totalAdults,
        totalChildren,
        children,
        null,
        null,
        null,
        askSuiteId,
        history,
        true
      )
    );
  };

  return (
    <div className="card-body">
      <div className="row pt-2 border-bottom">
        <div className="col">
          <h3 className="text-center">{t('coupon.title')}</h3>
        </div>
      </div>
      <div className="form-row px-2">
        <div className="col-12">
          <FormInput
            name="coupon"
            id="coupon-code"
            label={t('coupon.code')}
            value={currentCoupon}
            onInputChange={v => {
              setCurrentCoupon(v);
            }}
            uppercase
            transparent
            white
          />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col text-center">
          {hasAValidCoupon ? (
            <button
              type="button"
              className="btn btn-raised btn-danger"
              onClick={handleCouponRemoval}
            >
              {t('coupon.remove')}
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-raised btn-default"
              onClick={handleCouponSubmit}
            >
              {t('coupon.apply')}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

Coupon.propTypes = {
  reservation: PropTypes.object.isRequired,
};

export default Coupon;
