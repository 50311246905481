import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import About from './About';
import Gallery from './Gallery';
import PaymentMethods from './PaymentMethods';
import hqLogo from '../../../images/logo-hqbeds.png';
import Amenities from '../../Amenities';

const Footer = () => {
  const { t } = useTranslation();
  const property = useSelector(state => state.property);
  const {
    name: hostelName,
    photos,
    aboutInfoText,
    amenities,
    config: { creditCardPaymentEnabled, bankTransferPaymentEnabled },
  } = property;

  if (!property.ready) {
    return null;
  }

  return (
    <footer>
      <div className="row greydark">
        <div className="container">
          <div className="row px-5 px-md-4 pt-5 pb-0">
            <div className="col-12 col-md-7 col-lg-6 mb-4 overflow-hidden">
              <div className="about v-align">
                <div className="top">
                  <About text={aboutInfoText} />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-5 col-lg-3 mb-4 overflow-hidden">
              <Gallery hostel={hostelName} photos={photos} />
            </div>
            <div className="col-12 col-md-12 col-lg-3 mb-4 overflow-hidden">
              <div className="bottom">
                <Amenities slug="footer" amenities={amenities} footer />
              </div>
            </div>
          </div>
          <div className="row greydark border-top px-5 px-md-4 pt-4 pb-0">
            <div className="col-12 col-md-4 col-lg-3 mb-4 overflow-hidden">
              <h5 className="text-center text-lg-left secure">
                {t('info.secureSite')}
              </h5>
            </div>
            <div className="col-12 col-md-4 col-lg-6 mb-4 overflow-hidden">
              <PaymentMethods
                creditCardPaymentEnabled={creditCardPaymentEnabled}
                bankTransferPaymentEnabled={bankTransferPaymentEnabled}
              />
            </div>
            <div className="col-12 col-md-4 col-lg-3 mb-n5 text-center overflow-hidden">
              <img className="logo-footer" src={hqLogo} alt="HQBeds" />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
