import queryString from 'query-string';
import UrlHelper from './url-helper';

const PageHelper = {
  verifyInternalPages: (history, location) => {
    const { search: qs } = location;
    const urlParams = queryString.parse(qs);
    const { arrival, departure, coupon, currency, adults, children, room, _askSI, childrenAge } = urlParams;

    history.push(
      UrlHelper.toInternalRoute(
        '/',
        arrival,
        departure,
        coupon,
        currency,
        adults,
        children,
        room,
        _askSI,
        childrenAge
      )
    );

    return null;
  },

  scrollToId: id => {
    const access = document.getElementById(id);
    if (access) {
      access.scrollIntoView({ behavior: 'smooth' }, true);
    }
  },
};

export default PageHelper;
