import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import Header from '../../components/Structure/Header';
import Footer from '../../components/Structure/Footer';
import MainContent from '../../components/Structure/Content/Main';
import Availability from '../../components/Availability';
import Sidebar from '../../components/Structure/Sidebar';
import Room from '../../components/Rooms/Room';
import PageHelper from '../../helpers/page-helper';
import { setCurrentStep } from '../../store/application/actions';
import { STEP_AVAILABILITY } from '../../helpers/steps';
import RoomBlock from '../../components/Rooms/Room/RoomBlock';

const RoomsPage = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const travel = useSelector(state => state.travel);
  const property = useSelector(state => state.property);
  const application = useSelector(state => state.application);
  const availability = useSelector(state => state.availability);
  const { rooms: availabilityRooms } = availability;
  const reservation = useSelector(state => state.reservation);
  const { rooms: reservationRooms, summary } = reservation;
  const { rooms: selectedRooms } = summary;
  const { currentStep } = application;
  const { config: propertyConfig } = property;
  const { ready } = availability;
  const { preSelectedRoom, totalAdults } = travel;
  const [loaded, setLoaded] = useState(false);
  const utm_source = localStorage.getItem('utm_source');

  // Listing object room plan blocks 
  const superBlocksToPrint = [];

  useEffect(() => {
    if ((!ready || !property.ready || !availability.ready) && !loaded) {
      PageHelper.verifyInternalPages(history, location);
    }
  }, [ready, property.ready, availability.ready, loaded, history, location]);

  useEffect(() => {
    if (
      preSelectedRoom !== undefined &&
      preSelectedRoom !== null &&
      preSelectedRoom !== '' &&
      utm_source === 'googlehotel'
    ) {
      // pre-select choosen room
      const blockId = `block-room-${preSelectedRoom}`;
      setTimeout(() => PageHelper.scrollToId(blockId), 1000);
    }
  }, [preSelectedRoom, totalAdults, utm_source]);

  // todo, talvez só colocar um popup seja melhor.
  useEffect(() => {
    if (utm_source !== 'googlehotel') {
      setTimeout(() => PageHelper.scrollToId('roomsBlock'), 500);
    }
  }, [availability, utm_source]);

  if ((!ready || !property.ready || !availability.ready) && !loaded) {
    return null;
  }

  if (!loaded) {
    setLoaded(true);
  }

  if (currentStep !== STEP_AVAILABILITY) {
    dispatch(setCurrentStep(STEP_AVAILABILITY, true));
  }

  const sidebar = (
    <Sidebar
      property={property}
      reservation={reservation}
      page={STEP_AVAILABILITY}
      showAbout
      showCoupon
      showCheckoutSummary
    />
  );

  const config = {
    ...propertyConfig,
    reservationMaxGuests: availability.config.reservationMaxGuests,
    reservationMaxNights: availability.config.reservationMaxNights,
  };

  const roomPlanBlockList = [];
  const rtComposedIdList = [];
  let rtComposedIdListCounter = 0;
  
  if (Array.isArray(availabilityRooms) && availabilityRooms.length > 0) {

    let lastRoomTypeRealId = null;
    availabilityRooms.forEach(roomEntry => {

      const reservationRoom = reservationRooms.find(
        r => +r.id === +roomEntry.id
      );

      let autoSelect = 0;
      if (
        // eslint-disable-next-line radix
        parseInt(preSelectedRoom) ===
        // eslint-disable-next-line radix
        parseInt(roomEntry.roomTypeRealId) &&
        totalAdults > 0 &&
        utm_source === 'googlehotel'
      ) {
        autoSelect = totalAdults;
      }

      const totalGuests = reservationRoom?.guests?.length;

      const isRoomAlreadySelectedInAnotherPlan = selectedRooms.some(
        r =>
          r.roomTypeRealId === reservationRoom.roomTypeRealId &&
          r.ratePlanRealId !== null &&
          r.ratePlanRealId !== reservationRoom.ratePlanRealId
      );

      if (!rtComposedIdList[roomEntry.roomTypeRealId]) {
        rtComposedIdListCounter++;
        rtComposedIdList[roomEntry.roomTypeRealId] = rtComposedIdListCounter;
      }

      let actualRoomKey = rtComposedIdList[roomEntry.roomTypeRealId];

      if (!Array.isArray(roomPlanBlockList[actualRoomKey])) {
        roomPlanBlockList[actualRoomKey] = [];
      }

      roomPlanBlockList[actualRoomKey][roomEntry.id] = <Room
        key={`room-${roomEntry.id}`}
        room={roomEntry}
        lastRoomTypeRealId={lastRoomTypeRealId}
        config={config}
        reservationRoom={reservationRoom}
        totalGuests={totalGuests}
        reservationRooms={reservationRooms}
        autoSelect={autoSelect}
        isRoomAlreadySelectedInAnotherPlan={isRoomAlreadySelectedInAnotherPlan}
      />;

      lastRoomTypeRealId = roomEntry.roomTypeRealId;
    });
  }


  // Splitting into room plan blocks 
  const blocksToPrint = [];
  let blockCounter = 0;
  roomPlanBlockList.forEach(roomBlock => {
    if (!Array.isArray(blocksToPrint[blockCounter])) {
      blocksToPrint[blockCounter] = [];
    }
    Object.values(roomBlock).forEach(roomRatePlan => {
      blocksToPrint[blockCounter].push(roomRatePlan);
    });
    blockCounter++;
  });

  Object.values(blocksToPrint).forEach((roomPlans, index) => {
    superBlocksToPrint.push(<RoomBlock key={index}>{roomPlans}</RoomBlock>);
  });

  return (
    <>
      <Header />

      <MainContent sidebar={sidebar}>
        <div className="card mt-n4 mb-4">
          <Availability />
        </div>

        <div id="roomsBlock">
          {superBlocksToPrint}
        </div>
      </MainContent>

      <Footer />
    </>
  );
};

export default RoomsPage;
