import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Structure/Header';
import Footer from '../../components/Structure/Footer';
import MainContent from '../../components/Structure/Content/Main';
import Sidebar from '../../components/Structure/Sidebar';
import PageHelper from '../../helpers/page-helper';
import { setCurrentStep } from '../../store/application/actions';
import { STEP_PRODUCTS } from '../../helpers/steps';
import Product from '../../components/Products/Product';
import UrlHelper from '../../helpers/url-helper';

const ProductsPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const property = useSelector(state => state.property);
  const application = useSelector(state => state.application);
  const products = useSelector(state => state.products);
  const availability = useSelector(state => state.availability);
  const reservation = useSelector(state => state.reservation);
  const { arrivalDate, departureDate, couponCode, totalAdults: adults, totalChildren: children, askSuiteId } = reservation;
  const { currentStep } = application;
  const { ready } = availability;
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if ((!ready || !property.ready || !availability.ready) && !loaded) {
      PageHelper.verifyInternalPages(history, location);
    }
  }, [ready, property.ready, availability.ready, loaded, history, location]);

  if ((!ready || !property.ready || !availability.ready) && !loaded) {
    return null;
  }

  if (!loaded) {
    setLoaded(true);
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (currentStep !== STEP_PRODUCTS) {
      dispatch(setCurrentStep(STEP_PRODUCTS, true));
    }
  });

  if (products.ads.length <= 0) {
    history.push(
      UrlHelper.toInternalRoute(
        'checkout',
        arrivalDate,
        departureDate,
        couponCode,
        '', 
        adults, 
        children,
        null, 
        askSuiteId,
        ''
        )
    );

    return null;
  }

  const sidebar = (
    <Sidebar
      property={property}
      reservation={reservation}
      page={STEP_PRODUCTS}
      showAbout
      showCoupon
      showCheckoutSummary
    />
  );

  return (
    <Fragment>
      <Header />

      <MainContent sidebar={sidebar}>
        <Fragment>
          <div className="card mt-n4 mb-4 guests">
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <h2>{t('titles.productsAndServices')}</h2>
                  <div className="row mb-3 track-day">
                    {products.ads.map(product => {
                      return (
                        <Product
                          key={`product-${product.id}`}
                          product={product}
                          reservationProducts={reservation.products}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      </MainContent>

      <Footer />
    </Fragment>
  );
};

export default ProductsPage;
