import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router';
import * as PropertyThunks from '../../store/property/thunks';
import Header from '../../components/Structure/Header';
import Footer from '../../components/Structure/Footer';
import MainGallery from '../../components/MainGallery';
import Availability from '../../components/Availability';
import Sidebar from '../../components/Structure/Sidebar';
import MainContent from '../../components/Structure/Content/Main';
import { setCurrentStep } from '../../store/application/actions';
import { STEP_AVAILABILITY } from '../../helpers/steps';
import DateTimeHelper from '../../helpers/date-time-helper';

const HomePage = () => {
  /** @var {Function} dispatch */
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { search: qs } = location;
  const urlParams = queryString.parse(qs);
  const {
    arrival,
    departure,
    coupon,
    currency,
    adults,
    children,
    room,
    utm_source,
    _askSI,
    childrenAge
  } = urlParams;
  const property = useSelector(state => state.property);
  const application = useSelector(state => state.application);
  const { currentStep } = application;

  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (
      utm_source !== undefined &&
      utm_source !== null &&
      utm_source !== '' &&
      !localStorage.getItem('utm_source')
    ) {
      // eslint-disable-next-line no-undef
      localStorage.setItem('utm_source', utm_source);
    }
  }, [utm_source]);

  useEffect(() => {
    const arr = DateTimeHelper.formatDate(arrival);
    const dep = DateTimeHelper.formatDate(departure);
    
    dispatch(
      PropertyThunks.loadProperty(
        arr,
        dep,
        coupon,
        currency,
        adults,
        children,
        null,
        childrenAge,
        room,
        utm_source,
        _askSI,
        history,
      )
    );
  }, [
    arrival,
    departure,
    history,
    coupon,
    currency,
    adults,
    children,
    room,
    utm_source,
    _askSI,
    dispatch,
    childrenAge,
  ]);

  if (!property.ready) {
    return null;
  }

  if (currentStep !== STEP_AVAILABILITY) {
    dispatch(setCurrentStep(STEP_AVAILABILITY, true));
  }

  const sidebar = <Sidebar property={property} showAbout page="home" />;

  return (
    <Fragment>
      <Header />

      <MainContent sidebar={sidebar}>
        <Fragment>
          <div className="card mt-n4 mb-4">
            <Availability />
          </div>
          <div className="card mb-4">
            <MainGallery photos={property.widePhotos} />
          </div>
        </Fragment>
      </MainContent>

      <Footer />
    </Fragment>
  );
};

export default HomePage;
